import { defaults } from "ol/interaction";
import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { MaplibreLayer, RealtimeModes as modes } from "mobility-toolbox-js/ol";
import { RealtimeModes } from "mobility-toolbox-js/api";
// import { buffer } from "ol/extent";
import config from "../config";
import LocalStorage from "../LocalStorage";
import {
  DEBUG_MODES,
  DEBUG_MODE_DEFAULT,
  DEBUG_MODE_SBM,
  LS_BOOKMARKS_KEY,
} from "../constants";
import {
  CLEAR_DEPARTURES,
  CLEAR_GEOFOX_DEPARTURES,
  UPSERT_DEPARTURES,
  UPSERT_GEOFOX_DEPARTURES,
  UPSERT_DISRUPTIONS,
  SET_HIDDEN_DISRUPTIONS,
  UPDATE_BUS_TRAJECTORIES,
  UPDATE_EXTRA_GEOMS,
  UPDATE_STATION_IN_STATIONS,
  SET_STATION,
  SET_INCIDENT_PROGRAM,
  UPSERT_STATIONS,
  SET_HIGHLIGHT_STATION,
  SET_MAP,
  SET_MODE,
  SET_VISIBLE_STATIONS,
  SET_DEPARTURES_LOADING,
  SET_GEOFOX_DEPARTURES_LOADING,
  SET_NOTIFICATIONS_LOADING,
  SET_DEBUG_MODE,
  SET_NOTIFICATIONS,
  SET_SELECTED_NOTIFICATIONS_BY_ID,
  SET_SELECTED_VEHICLE,
  SET_RIS_POPUP,
  SET_IS_ROUTE_INFO_OPENED,
  SET_IS_FOLLOWING_VEHICLE,
  SET_BOOKMARKS,
  SET_BOOKMARKS_POPUP,
  UPDATE_LINE_IN_LINES,
  SET_STOP_SEQUENCE,
  SET_SELECTED_LINE,
  SET_IS_MOTS_RELATION_OPENED,
  SET_STOP_SEQUENCES,
  SET_STATION_ICONS_LEGEND_POPUP,
  SET_SCHEMATIC_BG_LAYER,
  SET_TOPOGRAPHIC_BG_LAYER,
  SET_PREVIEW_NOTIFICATION,
} from "./actions";
import { getNotificationsWithStatus } from "../utils";

const testDisruptions = [
  {
    content:
      "Aus betrieblichen Gründen entfallen heute Nachmittag folgende S-Bahnen des 10-Minuten-Takts auf der Linie S 8:<br><br>S 8 München Ost Abfahrt 16:47 Uhr - Germering-Unterpfaffenhofen Ankunft 17:17 Uhr<br>S 8 München Ost Abfahrt 18:27 Uhr - Gilching-Argelsried Ankunft 19:05 Uhr<br><br>S 8 Germering-Unterpfaffenhofen Abfahrt 17:21 Uhr - München Ost Ankunft 17:54 Uhr<br><br>Bitte beachten Sie, dass es heute Nachmittag auf der Linie S 8 zu weiteren Ausfällen der S-Bahnen des 10-Minuten-Takts kommen kann.",
    lines: [
      {
        color: "#000000",
        id: 8,
        name: "S8",
        stroke: "#fbc606",
        text_color: "#fbc606",
      },
    ],
    title: "Ausfälle des 10-Minuten-Takts auf der Linie S 8",
    updated: "2020-07-27T14:17:35+02:00",
  },
  {
    content:
      "Aus betrieblichen Gründen entfallen heute Nachmittag folgende S-Bahnen des 10-Minuten-Takts auf der Linie S 8:<br><br>S 8 München Ost Abfahrt 16:47 Uhr - Germering-Unterpfaffenhofen Ankunft 17:17 Uhr<br>S 8 München Ost Abfahrt 18:27 Uhr - Gilching-Argelsried Ankunft 19:05 Uhr<br><br>S 8 Germering-Unterpfaffenhofen Abfahrt 17:21 Uhr - München Ost Ankunft 17:54 Uhr<br><br>Bitte beachten Sie, dass es heute Nachmittag auf der Linie S 8 zu weiteren Ausfällen der S-Bahnen des 10-Minuten-Takts kommen kann.",
    lines: [],
    title: "Ausfälle des 10-Minuten-Takts",
    updated: "2020-07-27T14:17:35+02:00",
  },
];

const params =
  Object.fromEntries(new URLSearchParams(window.location.search)) || {};

// Defines the debug mode from the permalink.
let debugMode = false;
let isDebug = false;
if (DEBUG_MODES.includes(params.debug)) {
  debugMode = params.debug;
  isDebug = true;
}

const localStorage = new LocalStorage(config.localStorageKey);
const bookmarks = localStorage.getItem(LS_BOOKMARKS_KEY);
const mode = config[params.mode] ? params.mode : RealtimeModes.SCHEMATIC;
const x = parseFloat(params.x) || config[mode].x;
const y = parseFloat(params.y) || config[mode].y;
const z = parseFloat(params.z) || config[mode].z;
// const extent = config[mode].dataExtent;
const minZoom = isDebug ? 0 : config[mode].minZoom;
const maxZoom = isDebug ? 100 : config[mode].maxZoom;
const isSchematic = params.mode === RealtimeModes.SCHEMATIC;
const isTopographic = params.mode === RealtimeModes.TOPOGRAPHIC;
const topographic = config[RealtimeModes.TOPOGRAPHIC];
const schematic = config[RealtimeModes.SCHEMATIC];
const { notificationat } = params;

// We export for tests
export const initialState = {
  hiddenDisruptions: [],
  departures: [],
  departuresLoading: false,
  busTrajectories: [],
  extraLayerTags: [],
  highlightStation: null,
  stations: [],
  notifications: [],
  selectedNotifications: [],
  visibleStations: [],
  selectedVehicles: [],
  selectedLines: [],
  lines: [],
  isRouteInfoOpened: false,
  isMotsRelationOpened: false,
  isFollowingVehicle: false,
  mode,
  station: null,
  risPopup: false,
  stationIconsLegendPopup: false,
  bookmarksPopup: false,
  bookmarks: bookmarks ? JSON.parse(bookmarks) : [],

  // Map and Layers
  map: new Map({
    controls: [],
    interactions:
      !isDebug && !config.userInteractions
        ? []
        : defaults({ pinchRotate: false }),
    moveTolerance: 5,
    view: new View({
      zoom: z,
      center: [x, y],
      // TODO it could be nice to set the extent but we can't set again when mode changes"
      // extent: buffer(extent, extent[2] - extent[0]),
      minZoom,
      maxZoom,
    }),
  }),
  stationsLayer: new VectorLayer({
    zIndex: 1,
    source: new VectorSource(),
  }),
  topographicBgLayer:
    topographic.styleUrl &&
    new MaplibreLayer({
      key: 1,
      url: topographic.styleUrl,
      visible: isTopographic,
      mapOptions: {
        maxCanvasSize: [Infinity, Infinity],
      },
    }),
  schematicBgLayer: new MaplibreLayer({
    key: 2,
    url: schematic.styleUrl,
    visible: isSchematic,
    mapOptions: {
      maxCanvasSize: [Infinity, Infinity],
    },
  }),
  config,

  // From url parameters
  debug: debugMode,
  isDefaultDebug: debugMode === DEBUG_MODE_DEFAULT,
  isSbmDebug: debugMode === DEBUG_MODE_SBM,
  train: config.trainParam ? params.train : null,
  trainAuto: config.trainParam && /auto/i.test(params.train),
  line: config.lineParam ? params.line : null,
  auto: params.auto === "true",
  hideOthers: params.hideothers === "true",
  noDisruption: params.disruption === "false",
  noRouteInfo: params.routeinfo === "false",
  disruptions: params.disruptions === "true" ? testDisruptions : [],
};

export default function tralisApp(state = initialState, action) {
  switch (action.type) {
    case UPDATE_EXTRA_GEOMS:
      return {
        ...state,
        extraLayerTags: [...action.data],
      };
    case UPDATE_BUS_TRAJECTORIES:
      return {
        ...state,
        busTrajectories: [...action.data],
      };
    case UPSERT_DEPARTURES:
      return {
        ...state,
        departures: [...action.data],
      };
    case UPSERT_GEOFOX_DEPARTURES:
      return {
        ...state,
        geofoxDepartures: { ...action.data },
      };
    case UPSERT_DISRUPTIONS:
      if (state.noDisruption) {
        return {
          ...state,
          disruptions: [],
        };
      }
      if (params.disruptions === "true" && action.data && !action.data.length) {
        return {
          ...state,
          disruptions: testDisruptions,
        };
      }
      return {
        ...state,
        disruptions: action.data.slice(0) || [],
      };
    case SET_HIDDEN_DISRUPTIONS:
      return {
        ...state,
        hiddenDisruptions: action.data,
      };
    case SET_INCIDENT_PROGRAM:
      return {
        ...state,
        incidentProgram: action.data,
      };
    case SET_STATION:
      return {
        ...state,
        station: action.data.station,
        initialStationLoading: action.data.isStationLoadedFromPermalink,
      };
    case UPDATE_STATION_IN_STATIONS:
      if (!action.data) {
        return state;
      }
      // eslint-disable-next-line no-case-declarations
      const stationsToUpdate = action.data;
      // eslint-disable-next-line no-case-declarations
      const { stations } = state;
      // eslint-disable-next-line no-case-declarations
      stationsToUpdate.forEach((stationToUpdate) => {
        const idx = stations.findIndex(
          (station) => station.get("uic") === stationToUpdate.get("uic"),
        );
        if (idx > -1) {
          stations[idx] = stationToUpdate;
        } else {
          stations.push(stationToUpdate);
        }
      });
      return {
        ...state,
        stations: [...stations],
        stationsIds: stations.map((station) => station.get("uic")),
      };
    case UPDATE_LINE_IN_LINES:
      if (!action.data) {
        return state;
      }
      // eslint-disable-next-line no-case-declarations
      const lineToUpdate = action.data;
      // eslint-disable-next-line no-case-declarations
      const { lines } = state;
      // eslint-disable-next-line no-case-declarations
      const idx2 = lines.findIndex((line) => line.id === lineToUpdate.id);
      if (idx2 > -1) {
        lines[idx2] = lineToUpdate;
      } else {
        lines.push(lineToUpdate);
      }
      return {
        ...state,
        lines: [...lines],
      };
    case UPSERT_STATIONS:
      return {
        ...state,
        stations: action.data,
        stationsIds: (action.data || []).map((station) => station.get("uic")),
      };
    case SET_MODE:
      return {
        ...state,
        mode: action.data,
      };
    case SET_VISIBLE_STATIONS:
      return {
        ...state,
        visibleStations: action.data,
      };
    case CLEAR_DEPARTURES:
      return {
        ...state,
        departures: action.data,
      };
    case CLEAR_GEOFOX_DEPARTURES:
      return {
        ...state,
        geofoxDepartures: action.data,
      };
    case SET_HIGHLIGHT_STATION:
      return {
        ...state,
        highlightStation: action.data,
      };
    case SET_NOTIFICATIONS: {
      const notifications = action.data.slice(0);
      const now = notificationat ? new Date(notificationat) : new Date();
      const graph =
        state.mode === modes.SCHEMATIC ? "tralis_schematic_sbm" : "osm";
      const style = config[state.mode]?.style || "tralis_munich_schematic_v3";
      if (state.previewNotification?.[style]?.graphs[graph]) {
        const index = notifications.findIndex(
          (n) => n.properties.id === state.previewNotification[state.mode].id,
        );
        if (index > -1) {
          notifications[index] = state.previewNotification[style].graphs[graph];
        } else {
          notifications.push(state.previewNotification[style].graphs[graph]);
        }
      }

      return {
        ...state,
        notifications: getNotificationsWithStatus(notifications, now),
        selectedNotifications: notifications.filter((n) =>
          state.selectedNotifications
            .map((sn) => sn.properties.id)
            .includes(n.properties.id),
        ),
      };
    }
    case SET_TOPOGRAPHIC_BG_LAYER:
      return {
        ...state,
        topographicBgLayer: action.data,
      };
    case SET_SCHEMATIC_BG_LAYER:
      return {
        ...state,
        schematicBgLayer: action.data,
      };
    case SET_PREVIEW_NOTIFICATION: {
      const notifications = state.notifications.slice(0);
      const now = notificationat ? new Date(notificationat) : new Date();
      const graph =
        state.mode === modes.SCHEMATIC ? "tralis_schematic_sbm" : "osm";
      const style = config[state.mode]?.style || "tralis_munich_schematic_v3";
      if (action.data?.[style]?.graphs[graph]) {
        const index = notifications.findIndex(
          (n) => n.properties.id === action.data[style].id,
        );

        if (index > -1) {
          notifications[index] = action.data[style].graphs[graph];
        } else {
          notifications.push(action.data[style].graphs[graph]);
        }
      }

      return {
        ...state,
        previewNotification: action.data,
        notifications: getNotificationsWithStatus(notifications, now),
        selectedNotifications: notifications.filter((n) =>
          state.selectedNotifications
            .map((sn) => sn.properties.id)
            .includes(n.properties.id),
        ),
      };
    }
    case SET_SELECTED_NOTIFICATIONS_BY_ID:
      return {
        ...state,
        selectedNotifications: state.notifications.filter((n) =>
          action.data.includes(n.properties.id),
        ),
      };
    case SET_SELECTED_VEHICLE:
      if (state.noRouteinfo) {
        return {
          ...state,
          selectedVehicles: [],
          isRouteInfoOpened: false,
        };
      }
      return {
        ...state,
        selectedVehicles: action.data || [],
        isRouteInfoOpened: (action.data || []).length === 1,
      };
    case SET_SELECTED_LINE:
      return {
        ...state,
        selectedLine: action.data,
      };
    case SET_IS_ROUTE_INFO_OPENED:
      return {
        ...state,
        isRouteInfoOpened: action.data,
      };
    case SET_IS_MOTS_RELATION_OPENED:
      return {
        ...state,
        isMotsRelationOpened: action.data,
      };
    case SET_IS_FOLLOWING_VEHICLE:
      return {
        ...state,
        isFollowingVehicle: action.data,
      };
    case SET_DEPARTURES_LOADING:
      return {
        ...state,
        departuresLoading: action.data,
      };
    case SET_GEOFOX_DEPARTURES_LOADING:
      return {
        ...state,
        geofoxDeparturesLoading: action.data,
      };
    case SET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationsLoading: action.data,
      };
    case SET_DEBUG_MODE:
      return {
        ...state,
        debug: action.data,
        isDefaultDebug: action.data === DEBUG_MODE_DEFAULT,
        isSbmDebug: action.data === DEBUG_MODE_SBM,
      };
    case SET_MAP:
      return {
        ...state,
        map: action.data,
      };
    case SET_RIS_POPUP:
      return {
        ...state,
        risPopup: action.data,
      };
    case SET_STATION_ICONS_LEGEND_POPUP:
      return {
        ...state,
        stationIconsLegendPopup: action.data,
      };
    case SET_BOOKMARKS:
      // eslint-disable-next-line no-case-declarations
      const newBookmarks =
        action.data.length > 9 ? action.data.slice(0, 10) : action.data;
      localStorage.setItem(LS_BOOKMARKS_KEY, JSON.stringify(newBookmarks));
      return {
        ...state,
        bookmarks: newBookmarks,
      };
    case SET_BOOKMARKS_POPUP:
      return {
        ...state,
        bookmarksPopup: action.data,
      };
    case SET_STOP_SEQUENCE:
      return {
        ...state,
        stopSequence: action.data,
      };
    case SET_STOP_SEQUENCES:
      // eslint-disable-next-line no-case-declarations
      let stopSequence = action.data && action.data[0];

      if (state.stopSequence && stopSequence) {
        stopSequence =
          action.data.find(
            (stopSeq) =>
              stopSeq.routeIdentifier === state.stopSequence.routeIdentifier,
          ) || action.data[0];
      }

      return {
        ...state,
        stopSequence,
        stopSequences: action.data,
      };
    default:
      break;
  }

  return { ...state };
}
