import s1Arrow from "../../img/lines/s1pfeil.svg";
import s2Arrow from "../../img/lines/s2pfeil.svg";
import s3Arrow from "../../img/lines/s3pfeil.svg";
import s4Arrow from "../../img/lines/s4pfeil.svg";
import s5Arrow from "../../img/lines/s5pfeil.svg";
import s6Arrow from "../../img/lines/s6pfeil.svg";
import s7Arrow from "../../img/lines/s7pfeil.svg";
import s8Arrow from "../../img/lines/s8pfeil.svg";
import s20Arrow from "../../img/lines/s20pfeil.svg";
import busevArrow from "../../img/lines/busevpfeil.svg";
import diversity from "../../img/train-diversity-w150.svg";
import lifequality from "../../img/train-lifequality-w150.svg";

// Standard icons
import sCircle from "../../img/lines/skreis.svg";
import s1Circle from "../../img/lines/s1kreis.svg";
import s2Circle from "../../img/lines/s2kreis.svg";
import s3Circle from "../../img/lines/s3kreis.svg";
import s4Circle from "../../img/lines/s4kreis.svg";
import s5Circle from "../../img/lines/s5kreis.svg";
import s6Circle from "../../img/lines/s6kreis.svg";
import s7Circle from "../../img/lines/s7kreis.svg";
import s8Circle from "../../img/lines/s8kreis.svg";
import s20Circle from "../../img/lines/s20kreis.svg";
import busev from "../../img/lines/busevkreis.svg";
import busevNoRealtimeData from "../../img/lines/busev-gray.svg";
import sCircleNoRealtimeData from "../../img/lines/skreis-gray.svg";
import s1CircleNoRealtimeData from "../../img/lines/s1kreis-gray.svg";
import s2CircleNoRealtimeData from "../../img/lines/s2kreis-gray.svg";
import s3CircleNoRealtimeData from "../../img/lines/s3kreis-gray.svg";
import s4CircleNoRealtimeData from "../../img/lines/s4kreis-gray.svg";
import s5CircleNoRealtimeData from "../../img/lines/s5kreis-gray.svg";
import s6CircleNoRealtimeData from "../../img/lines/s6kreis-gray.svg";
import s7CircleNoRealtimeData from "../../img/lines/s7kreis-gray.svg";
import s8CircleNoRealtimeData from "../../img/lines/s8kreis-gray.svg";
import s20CircleNoRealtimeData from "../../img/lines/s20kreis-gray.svg";

// Christmas icons
// import sCircle from '../../img/lines/skreis_christmas.svg';
// import s1Circle from '../../img/lines/s1kreis_christmas.svg';
// import s2Circle from '../../img/lines/s2kreis_christmas.svg';
// import s3Circle from '../../img/lines/s3kreis_christmas.svg';
// import s4Circle from '../../img/lines/s4kreis_christmas.svg';
// import s6Circle from '../../img/lines/s6kreis_christmas.svg';
// import s7Circle from '../../img/lines/s7kreis_christmas.svg';
// import s8Circle from '../../img/lines/s8kreis_christmas.svg';
// import sCircleNoRealtimeData from '../../img/lines/skreis-gray_christmas.svg';
// import s1CircleNoRealtimeData from '../../img/lines/s1kreis-gray_christmas.svg';
// import s2CircleNoRealtimeData from '../../img/lines/s2kreis-gray_christmas.svg';
// import s3CircleNoRealtimeData from '../../img/lines/s3kreis-gray_christmas.svg';
// import s4CircleNoRealtimeData from '../../img/lines/s4kreis-gray_christmas.svg';
// import s6CircleNoRealtimeData from '../../img/lines/s6kreis-gray_christmas.svg';
// import s7CircleNoRealtimeData from '../../img/lines/s7kreis-gray_christmas.svg';
// import s8CircleNoRealtimeData from '../../img/lines/s8kreis-gray_christmas.svg';
// import s20CircleNoRealtimeData from '../../img/lines/s20kreis-gray_christmas.svg';

const mapLineIcons = {
  S: {
    circle: sCircle,
    arrow: s6Arrow,
  },
  S1: {
    circle: s1Circle,
    arrow: s1Arrow,
  },
  S2: {
    circle: s2Circle,
    arrow: s2Arrow,
  },
  S3: {
    circle: s3Circle,
    arrow: s3Arrow,
  },
  S4: {
    circle: s4Circle,
    arrow: s4Arrow,
  },
  S5: {
    circle: s5Circle,
    arrow: s5Arrow,
  },
  S6: {
    circle: s6Circle,
    arrow: s6Arrow,
  },
  S7: {
    circle: s7Circle,
    arrow: s7Arrow,
  },
  S8: {
    circle: s8Circle,
    arrow: s8Arrow,
  },
  S20: {
    circle: s20Circle,
    arrow: s20Arrow,
  },
  S_NoRealtime: {
    circle: sCircleNoRealtimeData,
    arrow: s6Arrow,
  },
  S1_NoRealtime: {
    circle: s1CircleNoRealtimeData,
    arrow: s1Arrow,
  },
  S2_NoRealtime: {
    circle: s2CircleNoRealtimeData,
    arrow: s2Arrow,
  },
  S3_NoRealtime: {
    circle: s3CircleNoRealtimeData,
    arrow: s3Arrow,
  },
  S4_NoRealtime: {
    circle: s4CircleNoRealtimeData,
    arrow: s4Arrow,
  },
  S5_NoRealtime: {
    circle: s5CircleNoRealtimeData,
    arrow: s5Arrow,
  },
  S6_NoRealtime: {
    circle: s6CircleNoRealtimeData,
    arrow: s6Arrow,
  },
  S7_NoRealtime: {
    circle: s7CircleNoRealtimeData,
    arrow: s7Arrow,
  },
  S8_NoRealtime: {
    circle: s8CircleNoRealtimeData,
    arrow: s8Arrow,
  },
  S20_NoRealtime: {
    circle: s20CircleNoRealtimeData,
    arrow: s20Arrow,
  },
  Diversity: {
    circle: diversity,
    arrow: s6Arrow,
  },
  Diversity_NoRealtime: {
    circle: diversity,
    arrow: s6Arrow,
  },
  LifeQuality: {
    circle: lifequality,
    arrow: s6Arrow,
  },
  LifeQuality_NoRealtime: {
    circle: lifequality,
    arrow: s6Arrow,
  },
  BusEV: {
    circle: busev,
    arrow: busevArrow,
  },
  BusEV_NoRealtime: {
    circle: busevNoRealtimeData,
    arrow: busevArrow,
  },
};

// For SBAHNMW-885, we automate the creation of other bus lines.
["S1", "S2", "S3", "S4", "S5", "S6", "S7", "S8", "S20", "EV", "SEV"].forEach(
  (line) => {
    mapLineIcons[`Bus${line}`] = {
      circle: busev,
      arrow: busevArrow,
    };
    mapLineIcons[`Bus${line}_NoRealtime`] = {
      circle: busevNoRealtimeData,
      arrow: busevArrow,
    };
  },
);

export default mapLineIcons;
